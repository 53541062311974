import React, { Component } from 'react';
import { Tabs } from 'antd';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import './index.less';

const TabPane = Tabs.TabPane;
const VillageResourceList = Loader.loadBusinessComponent('VillageResourceList');
const IconFont = Loader.loadBaseComponent('IconFont');
const CommunityListContent = Loader.loadBusinessComponent('CommunityListContent');
const MesRefresh = Loader.loadBusinessComponent('CommunityRefreshButton');
const tabArr = [
  { name: '常住人员', icon: 'icon-S_Bar_NameCard' },
  { name: '长期未出现人员', icon: 'icon-S_Edit_ClockStart' },
  { name: '全部', icon: 'icon-S_Photo_Type' }
];

@Decorator.withEntryLog() //日志
@withRouter
@Decorator.businessProvider('residentPerson', 'tab')
@observer
class ResidentPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectIf: true,
      activeKey: '1',
      longLoading: true,
      LongLiveList: [], //常驻人员
      RegisUnappList: [], //长期未出现人员
      allList: [], //全部人员
      total: 0,
      UnAppearTotal: 0,
      allTotal: 0,
      choseId: undefined,
      id: Math.random(),
      scrollId: Math.random(), //滚动条的key
      LongLoadMore: true,
    };
    this.once = [true,true,true]
    this.options = {
      page: 0
    };
    this.unoptions = {
      page: 0
    };
    this.allOptions = {
      page: 0
    }; //根据页码计算出请求数据的起始位置
    this.initData();
  }
  initData() {
    SocketEmitter.on(SocketEmitter.eventName.updatePerson, this.ProcessHandle);
  }
  ProcessHandle = () => {
    let { activeKey } = this.state;
    const { residentPerson } = this.props;
    residentPerson.editSearchData({ offset: 0 }, activeKey);
    this.requestData(activeKey, false, true /* ,true */);
  };
  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.updatePerson, this.ProcessHandle);
  }
  /**
   * @params {number} type 人员列表请求类型(1.常住人员 2.长期未出现人员 3.全部)
   * @params {boolean} loadmore 是否为滚动加载
   * @params {boolean} 是否需要重置列表请求起始位置
   */
  requestData = (type, loadmore, refreshKey) => {
    const { residentPerson } = this.props;
    const { LongLiveList, RegisUnappList, allList} = this.state;
    if (loadmore) {
      if (type == 1) {
        this.unoptions.page++;
        residentPerson.editSearchData({ offset: this.unoptions.page * 100 }, 1);
      }
      if (type == 2) {
        this.options.page++;
        residentPerson.editSearchData({ offset: this.options.page * 100 }, 2);
      }
      if (type == 3) {
        this.allOptions.page++;
        residentPerson.editSearchData({ offset: this.allOptions.page * 100 }, 3);
      }
    } else {
      this.setState({
        longLoading: true
      });
    }
    this.setState(
      {
        LongLoadMore: false
      },
      () => {
        Service.community
          .queryRegisteredPeople(residentPerson[type == 1 ? 'searchOption' : type == 2 ? 'searchOptionUnappear' : 'allSearchOption'])
          .then(res => {
            if (type == 1) {
              this.setState({
                LongLiveList: loadmore ? LongLiveList.concat(res.list) : res.list,
                total: res.total
              });
            }
            if (type == 2) {
              this.setState({
                RegisUnappList: loadmore ? RegisUnappList.concat(res.list) : res.list,
                UnAppearTotal: res.total
              });
            }
            if (type == 3) {
              this.setState({
                allList: loadmore ? allList.concat(res.list) : res.list,
                allTotal: res.total
              });
            }
            this.once[type - 1] = false
            this.setState(
              {
                longLoading: false
              },
              () => {
                if (refreshKey) {
                  this.recoverInitPage();
                }
              }
            );
            return Promise.resolve(res.list.length);
          })
          .then(length => {
            if (length >= 100) {
              this.setState({
                LongLoadMore: true
              });
            }
          })
          .catch(() => {
            this.setState({
              longLoading: false
            });
          });
      }
    );
  };
  /**请求全部类型数据 */
  FreShen = (type = false, fresh) => {
    let {activeKey} = this.state
    let { residentPerson } = this.props;
    if (fresh) {
      residentPerson.editSearchData({ offset: 0 }, activeKey);
      this.requestData(activeKey,false,true);
      this.recoverInitPage();
    } else {
      this.requestData(activeKey,true,false);
    }
  };
  HandleNoVillageData = () => {
    this.setState({
      LongLiveList: [],
      RegisUnappList: [],
      allList: []
    });
  };
  handleTableKey = key => {
    this.setState({
      LongLoadMore: true,
      activeKey: key,
      scrollId: Math.random()
    },() => {
      if(key == 1 && this.once[0] ){
        this.FreShen(false,true)
      }else if(key == 2 && this.once[1] ){
        this.FreShen(false,true)
      }else if(key == 3 && this.once[2]){
        this.FreShen(false,true)
      }
    });
  };

  
  /**社区总览跳转小区处理 */
  componentDidMount() {
    const { residentPerson } = this.props;
    residentPerson.initSearchData('all');
    const id = Utils.queryFormat(this.props.history.location.search).id || '';
    if (id.length > 0) {
      LM_DB.get('parameter', id).then(res => {
        let activeKey = res.activeKey;
        let searchData = Object.assign(res.communitySearchdata, {
          keywords: '',
          faceFeature: ''
        });
        let villageIds = searchData && searchData.villageIds.length == 1 ? searchData.villageIds[0] : undefined;
        if (villageIds) {
          residentPerson.editSearchData({ villageIds: [villageIds] }, 'all');
        }
        residentPerson.editSearchData(searchData, activeKey);
        this.setState({
          activeKey,
          choseId: villageIds,
          selectIf: villageIds ? false : true
        });
        this.FreShen(false,true);
      });
    } else {
      this.FreShen(false,true);
    }
  }
  handleSelctId = (id) => {
    this.setState({
      id: Math.random(),
      value: ''
    },() => {
      this.once=[true,true,true]
    });
  };
  /**刷新key */
  backTop = () => {
    this.setState({
      scrollId: Math.random()
    });
  };
  //初始化请求数据开始位置
  recoverInitPage = () => {
    this.options.page = 0;
    this.unoptions.page = 0;
    this.allOptions.page = 0;
    this.backTop();
  };
  /**记录indexDb */
  mergeSearchData = (activeKey, id) => {
    let { residentPerson, tab } = this.props;
    LM_DB.add('parameter', {
      id,
      activeKey: activeKey,
      communitySearchdata: toJS(
        activeKey == 1 ? residentPerson.searchOption : activeKey == 2 ? residentPerson.searchOptionUnappear : residentPerson.allSearchOption
      )
    }).then(() => {
      tab.goPage({
        moduleName: 'communityRegistered',
        location: this.props.location,
        isUpdate: true,
        data: { id },
        action: 'replace'
      });
    });
  };
  render() {
    const {
      selectIf,
      activeKey,
      total,
      id,
      choseId,
      UnAppearTotal,
      allTotal,
      longLoading,
      LongLiveList,
      RegisUnappList,
      allList,
      scrollId,
      LongLoadMore
    } = this.state;
    return (
      <div className="community_long_lived_alarm_box">
        <div className="community_left_total">
          <VillageResourceList
            mergeSearchData={this.mergeSearchData}
            handleSelctId={this.handleSelctId}
            type="registered"
            HandleNoVillageData={this.HandleNoVillageData}
            FreShen={this.FreShen}
            choseId={choseId}
            activeKey={activeKey}
            selectIf={selectIf}
          />
        </div>
        <div className="community_right_container">
          <MesRefresh activeKey={activeKey} isCover={true} FreShen={this.FreShen} total={activeKey == 1 ? total : activeKey == 2 ? UnAppearTotal : allTotal} />
          <Tabs type="card" onChange={this.handleTableKey} activeKey={activeKey}>
            {tabArr.map((v, i) => (
              <TabPane
                tab={
                  <span style={{ fontSize: '16px' }}>
                    <IconFont type={v.icon} />
                    <span style={{ fontSize: '14px' }}>{v.name}</span>
                  </span>
                }
                key={i + 1}
              />
            ))}
          </Tabs>
          <CommunityListContent
            recoverInitPage={this.recoverInitPage}
            LongLiveList={LongLiveList}
            RegisUnappList={RegisUnappList}
            allList={allList}
            scrollId={scrollId}
            id={id}
            activeKey={activeKey}
            requestData={this.requestData}
            longLoading={longLoading}
            total={activeKey == 1 ? total : activeKey == 2 ? UnAppearTotal : allTotal}
            type={activeKey == 1 ? 'resident' : activeKey == 2 ? 'float' : 'all'}
            isCover={true}
            mergeSearchData={this.mergeSearchData}
            LongLoadMore={LongLoadMore}
          />
        </div>
      </div>
    );
  }
}
export default ResidentPerson;
